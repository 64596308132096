import dayjs from 'dayjs'
import minMax from 'dayjs/plugin/minMax'

// dayjsのデフォルトlocaleがenなのでjaの設定が必要
import 'dayjs/locale/ja'

// プラグイン拡張
dayjs.extend(minMax)
dayjs.locale('ja')

export default dayjs
